import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    MenuItem,
    TextField,
    Typography,
    Tooltip, 
    IconButton, 
    SnackbarCloseReason,
} from '@mui/material';
import {HelpOutline, Toc} from '@mui/icons-material';
import styled from '@emotion/styled';
import axios from "axios";
import Cookies from 'js-cookie';
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Snackbar from '@mui/material/Snackbar';
import { createTheme } from "@mui/material/styles";
import Alert from '@mui/material/Alert';
import PageContainer from "./components/PageContainer";
import ConversionHistoryComponent from "./components/ConversionHistory";
import { useNavigate, useSearchParams } from 'react-router-dom';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

type ConvertedCurrency = {
    rate: number;
    amount: number;
};

export type ConversionHistoryItem = {
    toAmount: number;
    fromCurrency: number;
    toCurrency: number;
    rate: number;
    fromAmount: number;
};

type ConversionResult = {
    convertedCurrency: ConvertedCurrency;
    convertedCurrencyHistory: ConversionHistoryItem[];
};

export enum Currency {
    Rub = 1,
    Usd = 2,
    Eur = 3,
    Kzt = 4,
    Byn = 5
}

const currencyLabels: Record<Currency, string> = {
    [Currency.Rub]: 'RUB',
    [Currency.Usd]: 'USD',
    [Currency.Eur]: 'EUR',
    [Currency.Kzt]: 'KZT',
    [Currency.Byn]: 'BYN'
};

enum RegionType {
    Kazakhstan = 3,
    Russia = 1,
    Belarus = 2
}

const regionCurrencyMap: Record<RegionType, Currency> = {
    [RegionType.Kazakhstan]: Currency.Kzt,
    [RegionType.Russia]: Currency.Rub,
    [RegionType.Belarus]: Currency.Byn
};

const regionNames: Record<number, string> = {
    3: "Kazakhstan",
    1: "Russia",
    2: "Belarus"
};

enum RateSource {
    CentralBank = 1,
    ExchangePoint = 2,
}

const rateSourceLabels: Record<RateSource, string> = {
    [RateSource.CentralBank]: "ЦБ",
    [RateSource.ExchangePoint]: "Обменный пункт",
};

const updateConversionHistory = (conversionHistoryItem: ConversionHistoryItem) => {
    const maxHistoryItems = 5;
    const history = JSON.parse(Cookies.get('conversionHistory') || '[]');

    if (history.length >= maxHistoryItems) {
        history.shift();
    }

    history.push(conversionHistoryItem);
    Cookies.set('conversionHistory', JSON.stringify(history), {expires: 30});
};
function App() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const fromCurrencyFromUrl = searchParams.get("fromCurrency") || Currency.Usd;
    const toCurrencyFromUrl = searchParams.get("toCurrency") || Currency.Rub;
    const amountFromUrl = searchParams.get("amount") || "0";
    const regionTypeFromUrl = searchParams.get("regionType") || RegionType.Russia;
    const rateSourceFromUrl = searchParams.get("rateSource") || RateSource.CentralBank;

    const [fromCurrency, setFromCurrency] = useState<Currency>(fromCurrencyFromUrl as Currency);
    const [toCurrency, setToCurrency] = useState<Currency>(toCurrencyFromUrl as Currency);
    const [amount, setAmount] = useState<number>(Number(amountFromUrl));
    const [regionType, setRegionType] = useState<RegionType>(regionTypeFromUrl as RegionType);
    const [rateSource, setRateSource] = useState<RateSource>(rateSourceFromUrl as RateSource);
    
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [conversionResult, setConversionResult] = useState<ConversionResult | null>(null);
    const [loading, setLoading] = useState(false);

    const cookieHistory = JSON.parse(Cookies.get('conversionHistory') || '[]');

    const [conversionHistory, setHistory] = useState(cookieHistory);

    const saveParamsToCookies = () => {
        Cookies.set("fromCurrency", fromCurrency.toString());
        Cookies.set("toCurrency", toCurrency.toString());
        Cookies.set("amount", amount.toString());
        Cookies.set("regionType", regionType.toString());
        Cookies.set("rateSource", rateSource.toString());
    };

    useEffect(() => {
        const fromCurrencyCookie = fromCurrency || Cookies.get("fromCurrency");
        const toCurrencyCookie = toCurrency || Cookies.get("toCurrency");
        const amountCookie = amount || Cookies.get("amount");
        const regionTypeCookie = regionType || Cookies.get("regionType");
        const rateSourceCookie = rateSource || Cookies.get("rateSource");

        if (fromCurrencyCookie && toCurrencyCookie && amountCookie && regionTypeCookie && rateSourceCookie) {
            setFromCurrency(fromCurrencyCookie as unknown as Currency);
            setToCurrency(toCurrencyCookie as unknown as Currency);
            setAmount(Number(amountCookie));
            setRegionType(regionTypeCookie as unknown as RegionType);
            setRateSource(rateSourceCookie as unknown as RateSource);
            
            if(amount != 0) handleConvert();
        }
    }, []);
    
    useEffect(() => {
        setSearchParams((prevSearchParams) => {
            const newSearchParams = new URLSearchParams(prevSearchParams.toString());
            const updatedFromCurrency = fromCurrency !== prevSearchParams.get("fromCurrency") as unknown as Currency;
            const updatedToCurrency = toCurrency !== prevSearchParams.get("toCurrency") as unknown as Currency;
            const updatedAmount = String(amount) !== prevSearchParams.get("amount");
            const updatedRegionType = regionType !== prevSearchParams.get("regionType") as unknown as RegionType;
            const updatedRateSource = rateSource !== prevSearchParams.get("rateSource") as unknown as RateSource ;

            if (updatedFromCurrency) {
                newSearchParams.set("fromCurrency", fromCurrency.toString());
            }

            if (updatedToCurrency) {
                newSearchParams.set("toCurrency", toCurrency.toString());
            }

            if (updatedAmount) {
                newSearchParams.set("amount", String(amount));
            }

            if (updatedRegionType) {
                newSearchParams.set("regionType", regionType.toString());
            }

            if (updatedRateSource) {
                newSearchParams.set("rateSource", rateSource.toString());
            }

            if (updatedFromCurrency || updatedToCurrency || updatedAmount || updatedRegionType || updatedRateSource) {
                // @ts-ignore
                navigate(".", { search: newSearchParams.toString() });
            }
            return newSearchParams;
        });
    }, [fromCurrency, toCurrency, amount, regionType, rateSource, navigate, setSearchParams]);

    useEffect(() => {
        const newHistory = JSON.parse(Cookies.get('conversionHistory') || '[]');
        setHistory(newHistory);
    }, [conversionResult]);

    const showErrorSnackbar = (message: string) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const validateInputs = (): boolean => {
        if (amount === 0 || amount === null) {
            showErrorSnackbar('Заполните сумму для конвертации');
            return false;
        }

        if (fromCurrency === null) {
            showErrorSnackbar('Выберите валюту для конвертации');
            return false;
        }

        if (toCurrency === null) {
            showErrorSnackbar('Выберите валюту, в которую хотите конвертировать');
            return false;
        }

        return true;
    };

    const handleConvert = async () => {
        setLoading(true);
        try {
            if (!validateInputs()) {
                return;
            }
            
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/currencies/get-converted-currency`, {
                params: {
                    RegionType: regionType,
                    RateSourceType: rateSource,
                    FromCurrency: fromCurrency,
                    ToCurrency: toCurrency,
                    Amount: amount
                },
            });

            if (response.status === 200) {
                setConversionResult(response.data);
                const lastConversionHistoryItem = response.data.convertedCurrencyHistory.slice(-1)[0];
                updateConversionHistory(lastConversionHistoryItem);
                saveParamsToCookies();
            }
        } catch (error) {
            showErrorSnackbar('Произошла ошибка при конвертации');
            console.error('Error during conversion', error);
        }
        setLoading(false);
    };

    const swapCurrencies = () => {
        const tempCurrency = fromCurrency;
        setFromCurrency(toCurrency);
        setToCurrency(tempCurrency);
    };

    const handleShare = () => {
        navigator.clipboard.writeText(window.location.href)
            .then(() => {
                console.log('ok');
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <PageContainer>
        <StyledContainer maxWidth="sm">
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="error" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Grid sx={{marginBottom: 4, display: 'flex'}}>
                <FormControl>
                    <TextField
                        value={regionNames[regionType]}
                        variant="outlined"
                        label="Регион"
                        style={{width: "150px"}}
                        select
                        onChange={(e) => {
                            const selectedValue = e.target.value;
                            const newRegionType = Object.entries(RegionType).find(
                                ([, value]) => value === selectedValue
                            );
                            if (newRegionType) {
                                setRegionType(newRegionType[0] as unknown as RegionType);
                                setToCurrency(regionCurrencyMap[newRegionType[0] as unknown as RegionType]);
                            }
                        }}
                    >
                        {Object.values(RegionType)
                            .filter((value) => typeof value === "string")
                            .map((key) => (
                                <MenuItem key={key} value={key}>
                                    {key}
                                </MenuItem>
                            ))}
                    </TextField>
                </FormControl>
                <FormControl sx={{marginLeft: 2}}>
                    <TextField
                        label="Источник"
                        value={rateSource}
                        select
                        disabled
                        variant="outlined"
                        style={{width: "150px"}}
                        onChange={(e) => setRateSource(e.target.value as unknown as RateSource)}
                    >
                        {Object.entries(rateSourceLabels).map(([value, label]) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} sm={5}>
                    <FormControl fullWidth>
                        <TextField
                            style={{width: "100%"}}
                            variant="outlined"
                            select
                            label="Из валюты"
                            value={fromCurrency}
                            onChange={(e) => setFromCurrency(e.target.value as unknown as Currency)}
                        >
                            {Object.entries(currencyLabels).map(([value, label]) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Сумма"
                        type="number"
                        value={amount || ''}
                        onChange={(e) => setAmount(Number(e.target.value))}
                        inputProps={{min: 0}}
                        sx={{marginTop: 2}}
                    />
                </Grid>
                <Grid item xs={12} sm={2}  container alignItems="center" justifyContent="center">
                        <IconButton onClick={swapCurrencies}>
                            <SwapHorizIcon />
                        </IconButton>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <FormControl fullWidth>
                        <TextField
                            style={{width: "100%"}}
                            variant="outlined"
                            select
                            label="В валюту"
                            value={toCurrency}
                            onChange={(e) => setToCurrency(e.target.value as unknown as Currency)}
                        >
                            {Object.entries(currencyLabels).map(([value, label]) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Результат"
                        type="number"
                        value={conversionResult ? conversionResult.convertedCurrency.amount.toFixed(2) : ''}
                        InputProps={{readOnly: true}}
                        sx={{marginTop: 2}}
                    />
                </Grid>
            </Grid>
            <Grid item container alignItems="center" justifyContent="center">
                {conversionResult && (
                    <Typography sx={{textAlign: 'center', margin: '5px'}}>
                        Курс: {conversionResult.convertedCurrency.rate.toFixed(2)} {" "}
                        <Tooltip
                            title={
                                <Box>
                                    <Typography component="span" variant="body2">
                                        При конвертации НЕ в валюту региона или НЕ из валюты региона {regionNames[regionType]},
                                        сначала идет конвертация в валюту региона, а затем, из валюты региона происходит конвертация в целевую.
                                    </Typography>
                                    <Typography component="span" variant="body2" sx={{display: 'block', marginTop: 1}}>
                                        История конвертаций:
                                    </Typography>
                                    {conversionResult.convertedCurrencyHistory.map(
                                        (item: ConversionHistoryItem, index: number) => (
                                            <Typography component="span" variant="body2" key={index} sx={{display: 'block'}}>
                                                {index + 1}. {item.fromAmount.toFixed(2)} {currencyLabels[item.fromCurrency as Currency]} {"=>"} {item.toAmount.toFixed(2)} {currencyLabels[item.toCurrency as Currency]} по курсу {item.rate}
                                            </Typography>
                                        ))}
                                </Box>
                            }
                        >
                            <IconButton
                                size="small"
                                sx={{
                                    marginLeft: '-6px'
                                }}
                            >
                                <HelpOutline fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12} sm={5}>
                <Box sx={{ marginTop: 2, width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" color="primary" onClick={handleConvert}>
                        Конвертировать
                    </Button>
                </Box>
            </Grid>
            
            {conversionHistory && (<ConversionHistoryComponent conversionHistory={conversionHistory} currencyLabels={currencyLabels} />)}
        </StyledContainer>
        </PageContainer>
    );
}

export default App;