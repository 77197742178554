import React from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography, ListItem, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ConversionHistoryItem, Currency } from '../App';

interface ConversionHistoryProps {
    conversionHistory: ConversionHistoryItem[];
    currencyLabels: Record<Currency, string>;
}

const ConversionHistoryComponent: React.FC<ConversionHistoryProps> = ({ conversionHistory, currencyLabels }) => {
    return (
        <Grid sx={{ marginTop: 4, width: '100%' }}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="conversion-history-content"
                    id="conversion-history-header"
                >
                    <Typography>История конвертаций</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ width: '100%' }}>
                        {conversionHistory.slice().reverse().map((item: ConversionHistoryItem, index: number) => (
                            <ListItem key={index}>
                                <Typography sx={{ fontSize: '0.8rem' }}> {/* Уменьшенный размер шрифта */}
                                    {index + 1}. Из {item.fromAmount.toFixed(2)} {currencyLabels[item.fromCurrency as Currency]} {'>'} {item.toAmount.toFixed(2)} {currencyLabels[item.toCurrency as Currency]}
                                </Typography>
                            </ListItem>
                        ))}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};

export default ConversionHistoryComponent;